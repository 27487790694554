import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell primerament globós per passar de convex a aplanat sense obrir-se del tot i de 3 a 7 cm de diàmetre. És de color groc més fosc al centre i lluent en temps humit. Les làmines són fines i atapeïdes, adnades o un poc escotades devora el peu i de color groc que passen a verd violaci. El peu és cilíndric, de 3,5 a 10 x 3 a 7 mm de color groc sofre i provist de restes de cortina. Les espores són terrós porpra en massa, el·líptiques, amb un petit porus germinatiu, de 6-8 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      